<template>
  <v-app>
    <!-- Application Bar -->
    <AppBar :isUserLoggedIn="isUserLoggedIn" @logout="handleLogout" />

    <!-- Main Content -->
    <!-- <v-main>
        <br>
        <v-row>
          <v-col v-if="isUserLoggedIn">
            <routerLink to="/">Go to home</routerLink>
          </v-col>
          <v-col v-if="isUserLoggedIn">
            <routerLink to="/about">Go to About</routerLink>
          </v-col>
        </v-row> -->

    <router-view />
    <!-- </v-main> -->
  </v-app>
</template>

<script setup>

import { ref, onMounted } from 'vue';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { useRouter, useRoute } from 'vue-router';
import AppBar from './components/AppBar.vue';

var isUserLoggedIn = ref(false);
const router = useRouter();


onMounted(() => {

  // Obtén la ruta actual
  const route = useRoute();

  // Construye la URL actual
  const currentUrl = `${window.location.origin}${route.fullPath}`;

  switch (currentUrl) {
    case "https://horitzo-dev.web.app/":
      window.location.href = 'https://horitzo.app';
      break;
    case "https://horitzo-dev.firebaseapp.com/":
      window.location.href = 'https://horitzo.app';
      break;
    default:
      break;
  }
  
  console.log(currentUrl);


  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // El usuario está logueado
      console.log('Usuario logueado:', user.uid);

      isUserLoggedIn.value = true;
      console.log(isUserLoggedIn);


      // Puedes acceder a la información del usuario aquí
      // const uid = user.uid;
      // const email = user.email;
      // etc.

    } else {
      // El usuario no está logueado
      console.log('Usuario no logueado');
      isUserLoggedIn.value = false;

    }
  });

})


// Define the logout function
const handleLogout = async () => {
  const auth = getAuth();
  try {
    await signOut(auth);
    isUserLoggedIn.value = false;
    router.push('/signin');
  } catch (error) {
    console.error("Error logging out: ", error);
  }
}



</script>

<style>
/* Your styles here */
</style>
