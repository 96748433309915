<template>
	<v-container class="text-center">
		<v-row>
			<v-col>
				<h1>Página no encontrada</h1>
				<p>La página que estás buscando no existe.</p>
				<v-btn color="primary" @click="$router.push('/')">Ir a la página principal</v-btn>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: 'NotFound'
};
</script>

<style scoped>
/* Estilos personalizados para la página 404 */
</style>