// src/main.js
import { createApp } from 'vue';
import HoritzoApp from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import './styles.css'; // Importar el archivo CSS

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBBzJNRpUjwL1KjFVoZyb_bb5LfksOG1UM",
  authDomain: "horitzo-dev.firebaseapp.com",
  projectId: "horitzo-dev",
  storageBucket: "horitzo-dev",
  messagingSenderId: "1028062623454",
  appId: "1:1028062623454:web:3743fd1d1c6a83b903744f"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};

export { getCurrentUser };

loadFonts();

createApp(HoritzoApp)
  .use(router)
  .use(store)
  .use(vuetify)
  .mount('#app');
