<template>
  <v-container>
    <br>
    <br>
    <br>
  
    <v-row>
      <v-col cols="2">
        <v-img :width="200" aspect-ratio="1/1" cover :src="currentUser.photoURL"></v-img>
        <br>
        <h4>- {{ currentUser.displayName }}</h4>
        <h4>- {{ currentUser.email }}</h4>
      </v-col>
    </v-row>
  </v-container>



</template>

<script setup>
import { ref } from 'vue';
import { getCurrentUser } from '../main.js'

const currentUser = ref(await getCurrentUser());
</script>