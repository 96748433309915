import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
// import {  getCurrentUser } from 'firebase/auth'
import { getCurrentUser } from '../main';
import NotFound from '../components/NotFound.vue';
// import { getAuth, onAuthStateChanged } from 'firebase/auth'

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: true }
  },
  {
    path: '/signin', component: () => import('../views/SignInView.vue'),
    meta: { guest: true }
  },
  {
    path: '/about', component: () => import('../views/AboutView.vue'),
    meta: { requiresAuth: true }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isGuestRoute = to.matched.some(record => record.meta.guest);
  const currentUser = await getCurrentUser();

  if (requiresAuth && !currentUser) {
    next({ path: '/signin' });
  } else if (isGuestRoute && currentUser) {
    next(from.fullPath); // Redirige al usuario a la página anterior
  } else {
    next();
  }
});






// router.beforeEach( (to, from, next) => {
//   const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
//   const isGuestRoute = to.matched.some(record => record.meta.guest);
//   const isAuthenticated = getAuth().currentUser;

//   console.log(isGuestRoute);
//   console.log(isAuthenticated);
//   console.log(isGuestRoute && isAuthenticated);

//   // await getCurrentUser();

//   console.log('afterEach: ' + isAuthenticated);
//   console.log(']]]]');

//   if (requiresAuth && !isAuthenticated) {
//     next('/signin');
//   } else if (isGuestRoute && isAuthenticated) {
//     console.log('por aqui pasooo ');
//     console.log(from);
//     next(from.fullPath); // Redirige al usuario a la página anterior
//   } else {
//     console.log('hola');
//     next();
//   }
// });


// router.afterEach(async(to, from, next) => {
//   console.log(to, from, next);
//   const isAuthenticated = getAuth().currentUser;
//   const hola = await getCurrentUser();
//   console.log(hola);
//   console.log('afterEach: ' + isAuthenticated);

// })




// router.beforeEach(async (to, from, next) => {
//   // to and from are both route objects. must call `next`.
//   if (to.matched.some((record) => { record.meta.requiresAuth })) {
//     if (await getCurrentUser()) {
//       next();
//       console.log('por aqui paso 1');
//     } else {
//       console.log('por aqui paso 2');
//       next('/signin');
//     }
//   } else {
//     if(getAuth().currentUser){
//       next();
//     } else {
//       next('/signin');

//     }
//     console.log();
//     console.log('por aqui paso 3');
//   }
// })



// router.beforeEach((to, from, next) => {

//   console.log('===========----------');

//   console.log(to);
//   console.log(from);

//   console.log('===========----------');

//   const auth = getAuth();
//   const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

//   onAuthStateChanged(auth, user => {
//     if (requiresAuth && !user) {
//       next('/signin');
//     } else {
//       next();
//     }
//   });
// });

export default router
