<template>
	<v-app-bar :elevation="0" class="bg-primary" v-if="isUserLoggedIn">
		<template v-slot:prepend>
			<v-app-bar-nav-icon></v-app-bar-nav-icon>
		</template>
		<v-app-bar-title>Horitzo</v-app-bar-title>
		<v-btn @click="logout">logout</v-btn>
	</v-app-bar>
</template>

<script>
export default {
	name: 'AppBar',
	props: {
		isUserLoggedIn: {
			type: Boolean,
			required: true
		}
	},
	methods: {
		logout() {
			this.$emit('logout');
		}
	}
};
</script>

<style scoped>
/* Puedes agregar estilos personalizados aquí si es necesario */
</style>